/* eslint-disable jsx-a11y/media-has-caption */
import React, { useRef, useState } from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import Layout from '../components/layout'
import SEO from '../components/seo'
import heroMp4 from '../video/hero-muted.mp4'
import heroMp4Movil from '../video/heromovil.mp4'
import heroWebm from '../video/hero-muted.webm'
import videoPost from '../images/video-poster.jpg'
import Arrow from '../components/Arrow'
import styles from './index.module.css'
import Slider from 'react-slick'
import ModalGen from '../components/modalGen'

function IndexPage(props) {
  let isVideoDesk
  const {
    manHoldingTablet,
    reforma,
    insurgentes,
    santafe,
    one,
    heroes: { edges: heroes },
  } = props.data
  const images = {
    reforma,
    insurgentes,
    santafe,
    one,
    heroes: { edges: heroes },
  }
  const slider = useRef(null)
  function handleArrowClick() {
    const HERO_VH_SIZE = 1

    window.scrollTo({
      top: window.innerHeight * HERO_VH_SIZE,
      behavior: 'smooth',
    })
  }
  if (typeof screen !== 'undefined') {
    if (screen.width > 640) {
      isVideoDesk = false
      movildeskImg('movil')
    } else {
      isVideoDesk = true
      movildeskImg('desktop')
    }
  }

  function movildeskImg(ti) {
    var bandera = []
    for (var a = 0; a < heroes.length; a++) {
      bandera.push(heroes[a])
    }
    heroes.splice(0, heroes.length)
    for (var i = 0; i < bandera.length; i++) {
      var imageReformaMovil = bandera[i].node.childImageSharp.fluid.src.split(
        '/',
      )
      let n = imageReformaMovil[4].search(ti)
      if (n == -1) {
        heroes.push(bandera[i])
      }
    }
    // Acomodando imagenes segun su numero
    heroes.sort(function(a, b) {
      let srcA = a['node']['childImageSharp']['fluid']['src'].split('/')
      srcA = srcA[srcA.length - 1]
      let srcB = b['node']['childImageSharp']['fluid']['src'].split('/')
      srcB = srcB[srcB.length - 1]
      let numberImageA = srcA.split('-')
      numberImageA = parseInt(numberImageA['0'])
      let numberImageB = srcB.split('-')
      numberImageB = parseInt(numberImageB['0'])
      return numberImageA - numberImageB
    })
  }
  if (typeof screen !== 'undefined') {
    if (screen.width > 640) {
      isVideoDesk = false
    } else {
      isVideoDesk = true
    }
  }

  const [modal, setModal] = useState(true)
  const Toggle = () => setModal(!modal)

  return (
    <Layout>
      <SEO title="Downtown by Be Grand" main />

      <div className={styles.hero}>
        <video width="100%" muted loop playsInline autoPlay>
          {isVideoDesk == false && <source src={heroMp4} type="video/mp4" />}
          {isVideoDesk == true && (
            <source src={heroMp4Movil} type="video/mp4" />
          )}
          {`Sorry, your browser doesn't support embedded videos.`}
        </video>

        <div className={styles.heroContent}>
          <h1>
          RENTA Y PREVENTA DE OFICINAS EN LAS MEJORES
UBICACIONES DE LA CDMX
          </h1>
          <p>
            Work <span>sweet</span> work
          </p>
          <Arrow onClick={handleArrowClick} />
        </div>
      </div>
      <div className={styles.aboutUs}>
        <div className={styles.aboutUsContent}>
          <div styles={styles.aboutUsHeading}>
            <h2>Quienes somos</h2>
           
          </div>
          <i className="vertical-rule" />
          <div className={styles.aboutUsText}>
            <p>
            Downtown es la marca de oficinas A+ y usos mixtos de Be Grand, contamos con más de 20 años de experiencia en el desarrollo y comercialización de proyectos inmobiliarios.
            </p>
            <p>
            Garantizamos ofrecer espacios de primer nivel a prospectos que buscan adquirir propiedades para uso propio, inversión o arrendamiento.
            </p>
            <p>
            Contamos con excelentes ubicaciones dentro de la CDMX para cubrir las necesidades de las empresas. 

            </p>
          </div>
        </div>
      </div>
      <div className={styles.description}>
        <div className={styles.descriptionContent}>
          <div className={styles.descriptionImage}>
            <Img
              fixed={manHoldingTablet.childImageSharp.fixed}
              objectFit="cover"
              objectPosition="50% 50%"
              alt=""
            />
          </div>
          <div className={styles.descriptionText}>
            <h2>
              <strong>Work</strong> sweet <strong>work</strong>
            </h2>
            <p>
              La idea de crear un espacio que combina el confort con el estatus
              no es nueva, sin embargo, no hay un lugar que exitosamente haya
              logrado un ambiente que mezcle el factor cool en la idea de “una
              ciudad dentro de otra”. Entonces ¿por qué no concebir un lugar que
              integre a la perfección estatus, confort y creatividad?, donde el
              estilo corporativo se disipe un poco y permita a la gente sentirse
              en un lugar cómodo y placentero.
            </p>
            <p>
              Hoy Work Sweet Work es el concepto perfecto para trabajar en un
              lugar donde la sensación de tranquilidad, comodidad y realización
              estén de la mano.
            </p>
          </div>
        </div>
      </div>
      <div className={styles.developments}>
        <div className={styles.developmentsContent}>
          <div className={styles.developmentsHeading}>
            <h2>Nuestros desarrollos</h2>
            <p>
              Contamos con 4 desarrollos de oficinas ubicados en lugares
              estratégicos de la Ciudad de México, listas para adaptarse a las
              necesidades de tu empresa.
            </p>
          </div>
          <div className={styles.developmentsWrapper}>
            {[
              {
                name: 'reforma',
                banner: 'Venta',
                title: ['Downtown', 'Reforma'],
                url: 'reforma',
              },
              {
                name: 'insurgentes',
                //banner: 'Renta disponible',
                title: ['Downtown', 'Insurgentes'],
                url: 'insurgentes',
              },
              {
                name: 'santafe',
                title: ['Downtown', 'Santa Fe'],
                url: 'santa-fe',
              },
              {
                name: 'one',
                banner: 'Renta disponible',
                title: ['One o', 'One'],
                url: 'one-o-one',
              },
            ].map(({ banner, title, name, url }) => (
              <div key={name} className={styles.development}>
                <div className={styles.developmentImage}>
                  <Img
                    fixed={images[name].childImageSharp.fixed}
                    objectFit="cover"
                    objectPosition="50% 50%"
                    alt=""
                  />
                  {(name != 'santafe' && name != 'insurgentes') && (
                    <p className={styles.developmentBanner}>{banner}</p>
                  )}
                </div>

                <div className={styles.developmentFooter}>
                  <p className={styles.developmentFooterTitle}>
                    {title[0]}
                    <strong>{title[1]}</strong>
                  </p>
                  <hr />
                  <Link to={`/${url}`}>Conoce más</Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* <ModalGen show={modal} close={Toggle} /> */}
    </Layout>
  )
}

export const query = graphql`
  query {
    manHoldingTablet: file(relativePath: { eq: "manHoldingTablet.jpg" }) {
      childImageSharp {
        fixed(width: 400, height: 315) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    reforma: file(relativePath: { eq: "developments/reforma.png" }) {
      childImageSharp {
        fixed(width: 225, height: 225) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    insurgentes: file(relativePath: { eq: "developments/insurgentes.png" }) {
      childImageSharp {
        fixed(width: 225, height: 225) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    santafe: file(relativePath: { eq: "developments/santafe.png" }) {
      childImageSharp {
        fixed(width: 225, height: 225) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    one: file(relativePath: { eq: "developments/one.png" }) {
      childImageSharp {
        fixed(width: 225, height: 225) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    heroes: allFile(
      filter: { relativeDirectory: { eq: "images/home" }, name: { regex: "" } }
    ) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 2000, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`

export default IndexPage
